import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from 'views/login/Login.vue'
const NationalMachine = () => import('views/nationalMachine/NationalMachine.vue')
const SingleMachine = () => import('views/singleMachine/SingleMachine.vue');
const OilField = () => import('views/oilField/OilField.vue');

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/NationalMachine',
    name: 'NationalMachine',
    component: NationalMachine
  },
  {
    path: '/SingleMachine',
    name: 'SingleMachine',
    component: SingleMachine
  },
  {
    path: '/OilField',
    name: 'OilField',
    component: OilField
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
